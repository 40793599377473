(function ($) {
  "use strict";
  $(document).ready(function () {
    var slider,
      sliderConfig = {
        pager: false,
        // nextSelector: '#slider-next',
        // prevSelector: '#slider-prev'
      };

    var updateQueryStringParameter = function (uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    };

    var queryParams = {};
    location.search.substr(1).replace(/([^&=]*)=([^&]*)&?/g, function () {
      queryParams[decodeURIComponent(arguments[1])] = decodeURIComponent(
        arguments[2]
      );
    });

    if (queryParams.itemId)
      $('[data-target="#' + queryParams.itemId + '"]').click();

    $(".modal")
      .on("show.bs.modal", function () {
        history.pushState(
          null,
          null,
          updateQueryStringParameter(
            window.location.href,
            "itemId",
            this.getAttribute("id")
          )
        );
      })
      .on("hide.bs.modal", function () {
        window.history.back();
      })
      .on("shown.bs.modal", function () {
        slider = $(".bxslider", this).bxSlider(sliderConfig);
        $(".scrollable", this).show().mCustomScrollbar({ theme: "dark-thin" });
        $(".share-icons").jsSocials({
          showLabel: false,
          showCount: false,
          shares: ["twitter", "facebook", "googleplus"],
        });
      })
      .on("hidden.bs.modal", function () {
        if (slider) slider.destroySlider();
        $(".scrollable", this).mCustomScrollbar("destroy").hide();
        $(".share-icons").jsSocials("destroy");
      });

    // =========================

    $(
      ".prices .scrollable, .gallery-image-box-special-packages .scrollable"
    ).mCustomScrollbar({
      theme: "light-thin",
      autoHideScrollbar: true,
    });

    // =========================

    // $('a[data-toggle="modal"]').click(function () {
    //     var $this = $(this),
    //         $thumbnailImage = $this.find('.thumbnail img');
    //     if ($thumbnailImage.length > 0) {
    //         var imageSrc = $thumbnailImage.attr('src');
    //         $('meta[property="og:image"]').attr('content', imageSrc);
    //     } else {
    //         var $bgImage = $this.find('.bg-image');
    //         if ($bgImage.length > 0) {
    //             var backgroundImage = $bgImage.css('background-image').trim(),
    //                 backgroundImageUrl = backgroundImage.substring(5, backgroundImage.length - 1);
    //             $('meta[property="og:image"]').attr('content', backgroundImageUrl);
    //         }
    //     }
    // });

    // =========================

    $(".youtube-thumbnail img").each(function () {
      var matches = this.src.match(/v=([\w\-]+)/);
      this.src = "https://img.youtube.com/vi/{videoId}/hqdefault.jpg".replace(
        "{videoId}",
        matches[1]
      );
    });

    $("iframe.youtube-video").each(function () {
      var matches = this.src.match(/v=([\w\-]+)/);
      this.src = "https://www.youtube.com/embed/{videoId}".replace(
        "{videoId}",
        matches[1]
      );
    });

    $(".bg-image[data-youtube-video]").each(function () {
      var matches = this.dataset.youtubeVideo.match(/v=([\w\-]+)/);
      $(this).css(
        "background-image",
        "url('https://img.youtube.com/vi/{videoId}/hqdefault.jpg')".replace(
          "{videoId}",
          matches[1]
        )
      );
    });

    // =========================

    $(".dotdotdot").dotdotdot();

    // =========================

    function equalHeights() {
      $(".eh").css("height", "auto").equalHeights();
    }

    $(window).on("resize", equalHeights).resize();

    $("#intro").find("ul.bxslider").bxSlider({
      auto: true,
      controls: false,
      pager: false,
      pause: 15000,
    });

    // =========================

    $('select[name="year"]').on("change", function () {
      if (this.value) {
        $('select[name="month"]').removeAttr("disabled");
      } else {
        $('select[name="month"]').attr("disabled", "disabled").val("");
      }
    });

    if ($('select[name="year"]').val()) {
      $('select[name="month"]').removeAttr("disabled");
    }

    // =========================

    // Check to see if the window is top if not then display button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $("#scrollToTop").fadeIn();
      } else {
        $("#scrollToTop").fadeOut();
      }
    });

    // Click event to scroll to top
    $("#scrollToTop").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        200
      );
    });

    // =========================

    $(document).on("click", ".bubble-video", function () {
      $(this).toggleClass("play");
      var video = this.getElementsByTagName("video")[0];
      if (video.paused) {
        // Play the video
        video.play();
      } else {
        // Pause the video
        video.pause();
      }
    });

    setTimeout(function () {
      // $('.bubble').fadeOut();
      $(".bubble").addClass("bubble-video");
    }, 3000);

    var videoPlayer = document.getElementById("main-video-player");
    if (videoPlayer) {
      var videos = [
        "http://s3.eu-central-1.amazonaws.com/zarbazani-project-files/samraan%2Fc479e742-d930-4bee-8d2c-76d73ef3f1d0_home-video-1.mp4",
        "http://s3.eu-central-1.amazonaws.com/zarbazani-project-files/samraan%2F60fe3b69-a73b-4ddc-8981-6521d99fce33_home-video-2.mp4",
      ];

      var currentVideoIndex = 0;
      videoPlayer.addEventListener(
        "ended",
        function () {
          currentVideoIndex++;
          if (currentVideoIndex === videos.length - 1) currentVideoIndex = 0;
          videoPlayer.setAttribute("src", videos[currentVideoIndex]);
          videoPlayer.play();
        },
        false
      );
    }
  });
})(jQuery);